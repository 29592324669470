@import "../../App.scss";

.backers-main-container {
}

.numbers-container {
  text-align: center;
  background: linear-gradient($pageTitleColor 75%, white 75%);
  color: white;
  padding: 35px 5%;
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    background-image: linear-gradient($pageTitleColor 58%, white 58%);
    padding: 65px 5%;
  }

  @media (min-width: $breakpoint-laptop) {
    background-image: linear-gradient($pageTitleColor 58%, white 58%);
    padding: 65px 15%;
  }
}

.hexagon {
  //   -webkit-filter: drop-shadow(5px 5px 5px #222222);
  //   filter: drop-shadow(0 0 5px #222222);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 158px;
  color: black;
  position: relative;

  @media (min-width: $breakpoint-tablet) {
    width: 255px;
    height: 285px;
  }
}

// .hexagon-group {
//   display: flex;
//   justify-content: space-evenly;
// }

.hexagon-group-container {
  display: flex;
  justify-content: space-evenly;
  width: 300px;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (min-width: $breakpoint-tablet) {
    min-width: 1110px;
    justify-content: space-between;
    margin: 0;
    width: 100%;
  }
}

.backers-container {
  text-align: left;
  display: flex;
  padding: 20px 5%;
  border-top: 1px solid #e0e0e0;
  flex-direction: column;

  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    flex-direction: row;
    padding: 70px 10%;
    justify-content: space-between;
  }

  @media (min-width: $breakpoint-laptop) {
    flex-direction: row;
    padding: 70px 15%;
    justify-content: space-between;
  }
}

.numbers-font {
  font-size: 22px;
  font-family: InterExtraBold;
  line-height: 1.3;

  @media (min-width: $breakpoint-tablet) {
    font-size: 48px;
  }
}

.hexagon-numbers-text {
  font-family: Inter;
  font-size: 10px;
  line-height: 2;
  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
  }
}

.backers-logo {
  width: 100%;
  height: 100%;
}

.backers-logos {
  margin: 10px 16px;
  max-width: 100px;
  max-height: 129px;
  @media (min-width: $breakpoint-tablet) {
    margin: 30px;
    min-height: 50px;
    max-width: 173px;
    max-height: 129px;
  }
}

.backers-logo-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $breakpoint-tablet) {
    flex: 0.7;
    justify-content: center;
    max-width: 730px;
  }
}

.hexagon-image {
  width: 100%;
  height: 100%;
  position: absolute;
}

.position-relation {
  position: relative;
}

.backers-text-max-width {
  max-width: 350px;
  margin: 8px 0 16px;
  @media (min-width: $breakpoint-tablet) {
    margin: 8px 0 32px;
  }
}

.numbers-text-max-width {
  margin: 0px 0 24px;

  @media (min-width: $breakpoint-tablet) {
    margin: 8px 0 32px;
  }
}
