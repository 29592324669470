@import "../../App.scss";

.client-card {
  display: flex;
  width: 128px;
  height: 72px;
  border-radius: 16px;
  background-color: white;
  @media (min-width: $breakpoint-tablet) {
    width: 170px;
    height: 96px;
  }
}

.client-logo {
  align-items: center;
  height: 51px;
  margin: auto;
}
