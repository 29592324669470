@import "../../App.scss";

$shadowColor: #c4c4c4;

.image-card-container {
  display: flex;
  flex-direction: column;
  // height: 388px;
  margin-left: 4px;
  margin-bottom: 6px;
  min-height: 389px;
  width: 306px;
  border-radius: 24px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.12);

  @media (max-width: 320px) {
    width: 256px;
  }

  @media (min-width: $breakpoint-tablet) {
    height: 524px;
    min-width: 399px;
    max-width: 400px;
    margin: 0px 20px;
  }
}

.card-image {
  height: 193px;
  border-radius: 24px 24px 0px 0px;
  @media (min-width: $breakpoint-tablet) {
    height: 346px;
  }
}

.image-card-text-container {
  padding: 26px;
}

.image-card-title {
  font-family: InterExtraBold;
  color: $pageTitleColor;
  font-size: 18px;
  line-height: 1.5;
  text-align: left;

  @media (min-width: $breakpoint-tablet) {
    font-size: 24px;
  }
}

.image-card-text {
  font-family: Inter;
  font-size: 16px;
  line-height: 2;
  text-align: left;
  margin: 12px 0 0;

  @media (min-width: $breakpoint-tablet) {
    margin: 16px 0 0;
  }
}
