@import "../../App.scss";

.image-size {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pictures-container {
  padding: 80px 5%;

  @media (min-width: $breakpoint-laptop) {
    padding: 80px 15%;
  }
}

.picture-collage-container {
  min-width: 1280px;
  height: 751px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.picutre-collage-text {
  white-space: normal;
  text-align: left;
  margin: 16px 0 0;
  @media (min-width: $breakpoint-tablet) {
    margin: 32px 0 85px 0;
    white-space: pre-line;
  }
}

.slider-pic-container {
  margin: 0 24px 0 0;
  height: 228px;
  width: 280px;
  max-width: 280px;
}

.slider-image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
