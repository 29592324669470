@import "../../App.scss";

$inputBgColor: #fafafa;
$labelColor: #9e9e9e;
$buttonColor: #1c75bc;

@media (min-width: $breakpoint-tablet) {
}

.contact-form {
  display: flex;
  border-radius: 12px;
  padding: 30px 0;
  margin: 0 5%;
  max-width: 950px;

  box-shadow: 0 0 11px $shadowColor;
  background-color: white;

  @media (min-width: $breakpoint-tablet) {
    // margin: 0 15% 100px 15%;
    margin: 0 auto 100px;
    height: 735px;
  }
}

.form-container {
  width: 100%;
  margin: auto 0;
  @media (min-width: $breakpoint-tablet) {
    // width: 80%;
  }
}

.contact-column-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 5%;
  @media (min-width: $breakpoint-tablet) {
    flex: 0.5;
  }
}

.contact-column-right {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 30px;

  @media (min-width: $breakpoint-tablet) {
    flex: 0.5;
    padding: 0;
  }
}

.contact-input-label {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.5;
  color: $labelColor;
}

.contact-input {
  background-color: $inputBgColor !important;
  height: 58px;
}

.contact-form-button {
  border-radius: 100px;
  background-color: $buttonColor;
  font-family: InterBold;
  width: 100%;
  color: white;
  border: none;
  height: 50px;
  margin-top: 20px;
}

.form-title {
  font-family: InterBold;
  font-size: 16px;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    margin: 0 0 30px 0;
    font-size: 24px;
    white-space: pre-line;
    line-height: 1.5;
    text-align: left;
  }
}

.flexible-row {
  display: flex;
  margin: 0 0 32px;
  line-height: 2;
  align-items: center;

  p {
    padding-left: 5%;
  }

  img {
    margin-left: 5%;
  }
  @media (min-width: $breakpoint-tablet) {
    margin: 0 0 32px;
    img {
      margin-left: 0;
    }
  }
}

.icon-container-padding {
  // padding-bottom: 30px;
}

.contact-icons {
  width: 24px;
  height: 24px;
}

.contact-info-margin {
  @media (min-width: $breakpoint-tablet) {
    margin: 149px 0 0;
  }
}

.flexible-row-left-padding {
  margin-left: 5%;

  @media (min-width: $breakpoint-tablet) {
    margin-left: 0;
  }
}
