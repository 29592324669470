@import "../../App.scss";

$navFontColor: #101112;

.navbar-main-container {
  padding: 0 5%;
  // background-color: white;
  // height: 100vh;

  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    height: 88px;
    background-color: transparent;
  }

  @media (min-width: $breakpoint-laptop) {
    height: 88px;
    padding: 0 15%;
    background-color: transparent;
  }
}

.navbar-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
}

.navbar-logo-container {
  width: 151px;
  height: 49px;
}

.navbar-logo {
  width: 100%;
  height: 100%;
}

.navlinks-container {
  width: 574px;
  display: flex;
  justify-content: space-between;
}

.nav-fonts {
  text-decoration: none;
  color: $navFontColor;
  font-family: Inter;
  font-size: 20px;
  margin: 0 0 48px;
  @media (min-width: $breakpoint-tablet) {
    font-family: InterBoldTwo;
    font-size: 16px;
    text-transform: none;
    margin: 0;
    text-transform: uppercase;
  }
}

.nav-fonts-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-font-active {
  color: $pageTitleColor;
}

.navbar-expand-containerss {
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  // position: absolute;
}

.burger-icon-container {
  max-width: 32px;
  height: 32px;
}
