@import "~bootstrap/scss/bootstrap";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

$backgroundColor: #edf7ff;
$pageTitleColor: #1c75bc;
$shadowColor: #c4c4c4;
$locationTextColor: #757575;
$breakpoint-tablet: 1025px;
$breakpoint-laptop: 1441px;

@font-face {
  font-family: "Inter";
  src: url(./assets/Inter/static/Inter-Regular.ttf);
}
@font-face {
  font-family: "InterBold";
  src: url(./assets/Inter/static/Inter-SemiBold.ttf);
}
@font-face {
  font-family: "InterExtraBold";
  src: url(./assets/Inter/static/Inter-ExtraBold.ttf);
}
@font-face {
  font-family: "InterBoldTwo";
  src: url(./assets/Inter/static/Inter-Bold.ttf);
}
@font-face {
  font-family: "InterMedium";
  src: url(./assets/Inter/static/Inter-Medium.ttf);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-title {
  font-family: InterBold;
  font-size: 26px;
  line-height: 1.3;
  color: $pageTitleColor;

  @media (min-width: $breakpoint-tablet) {
    font-size: 54px;
    white-space: pre-line;
  }
}

.page-text {
  font-family: Inter;
  font-size: 14px;
  line-height: 2;

  @media (min-width: $breakpoint-tablet) {
    font-size: 24px;
    white-space: pre-line;
  }
}

.section-title {
  font-family: InterExtraBold;
  font-size: 16px;
  line-height: 1.5;

  @media (min-width: $breakpoint-tablet) {
    font-size: 40px;
  }
}

.responsive-section-title {
  font-family: InterExtraBold;
  font-size: 22px;
  line-height: 1.5;

  @media (min-width: $breakpoint-tablet) {
    font-size: 40px;
  }
}

.section-text {
  font-family: Inter;
  font-size: 14px;
  line-height: 2;
  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
  }
}

.angled-background {
  background-image: linear-gradient(5deg, $backgroundColor, white);
  height: 700px;
}

.openings-button {
  font-family: InterBold;
  font-size: 20px;
  width: 290px;
  background-color: $pageTitleColor;
  border-radius: 100px;
  border: none;
  height: 50px;
  // width: 250px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.slanted-background {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  // background: linear-gradient(
  //   4deg,
  //   #ffffff 50%,
  //   $backgroundColor calc(50% + 2px)
  // );
  // transform: skew(95deg);
  // background-color: $backgroundColor;
  z-index: -1;
}

.position-relative {
  position: relative;
}

.slider-div {
  padding: 0;
}
p {
  margin: 0;
}

.overflow-x-hidden {
  @media (max-width: $breakpoint-tablet) {
    overflow-x: hidden;
  }
}

.button-arrow {
  display: flex;
  font-family: InterBold;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 16px;
  height: 40px;
  background-color: #1c75bc;
  color: white;
  border: none;
  width: 148px;
  margin: 0 16px 0 0;
  @media (min-width: $breakpoint-tablet) {
    font-size: 20px;
    height: 50px;
    width: 191px;
    margin: 0 24px 0 0;
  }
}
