@import "../../App.scss";

.solution-main-container {
  background-color: $backgroundColor;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    margin-top: 75px;
    padding: 0 0 0 5%;
  }

  @media (min-width: $breakpoint-laptop) {
    padding: 0 0 0 15%;
    margin-top: 75px;
  }
}

.solution-container {
  padding: 45px 0 63px 0;
  display: flex;
  flex-direction: column;
  @media (min-width: $breakpoint-tablet) {
    padding: 80px 0 158px 0;
  }
}

.solution-card-container {
  display: flex;
  // padding-top: 45px;
  flex-direction: column;

  @media (min-width: $breakpoint-tablet) {
    // padding-top: 70px;
    flex-direction: row;
  }
}

.solution-card-container2 {
  display: flex;
  // padding-top: 45px;
  flex-direction: column;

  @media (min-width: $breakpoint-tablet) {
    padding-top: 70px;
    flex-direction: row;
  }
}

.solution-image-container {
  height: 874px;
}

.solution-image {
  height: 100%;
}

.freight-title-max-width {
  max-width: 320px;
  margin: 0 0 32px;
  @media (min-width: $breakpoint-tablet) {
    margin: 0 0 73px;
    max-width: 720px;
  }
}
