@import "../../App.scss";

$buttonTextColor: #1c75bc;

.contact-us-container {
  display: flex;
  flex-direction: column;
  height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(28, 117, 188, 0.3)),
    url(https://dg25pvdw618xy.cloudfront.net/contact_us_section_shipper.jpg);
  background-position: center;
  justify-content: center;
  align-items: center;
  background-size: cover;

  @media (min-width: $breakpoint-tablet) {
    height: 480px;
  }
}

.contact-us-container2 {
  display: flex;
  flex-direction: column;
  height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(28, 117, 188, 0.3)),
    url(https://dg25pvdw618xy.cloudfront.net/contact_us_section_carriers.jpg);
  background-position: center;
  justify-content: center;
  align-items: center;
  background-size: cover;

  @media (min-width: $breakpoint-tablet) {
    height: 480px;
  }
}

.contact-us-text {
  font-size: 16px;
  font-family: InterBold;
  line-height: 1.5;
  color: white;
  padding: 0 8%;
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    font-size: 40px;
    line-height: 1.3;
    padding: 0 100px;
    margin-bottom: 56px;
  }

  @media (min-width: $breakpoint-laptop) {
    font-size: 40px;
    line-height: 1.3;
    padding: 0 15%;
    margin-bottom: 56px;
  }
}

.contact-us-button {
  background-color: white;
  width: 206px;
  height: 50px;
  color: #101112;
  border-radius: 100px;
  border: none;
  font-family: InterBold;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: $breakpoint-tablet) {
    font-size: 20px;
    line-height: 1.5;
  }
}

.contact-button-link {
  text-decoration: none;
}
