body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Override BambooHR styles */
.BambooHR-ATS-board {
  font-family: inherit !important;
  padding: 40px 5% 60px 5% !important;
}

.BambooHR-ATS-board>h2 {
  display: none;
}

.BambooHR-ATS-Department-Header {
  font-family: InterExtraBold;
  font-size: 24px;
  color: #1c75bc;
  padding-bottom: 6px;
  border-bottom: 1px solid #9e9e9e;
}

.BambooHR-ATS-Jobs-List {
  padding: 0 !important;
  margin: 16px 0 !important;
  border: 0 !important;
}

.BambooHR-ATS-Jobs-List li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0  !important;
}

.BambooHR-ATS-Jobs-List li a {
  font-family: InterMedium;
  font-weight: normal;
  font-size: 18px;
  color: #4e4c4c;
  text-decoration: none !important;
}

.BambooHR-ATS-Jobs-List li a:hover {
  color: #000000;
  font-weight: 600 !important;
  text-decoration: underline !important;
}

.BambooHR-ATS-Location {
  padding: 0 !important;
  font-size: 18px !important;
  font-family: InterMedium !important;
  color: #757575 !important;
}