@import "../../App.scss";

.description-container {
  padding: 45px 5% 45px 5%;
  text-align: center;

  @media (min-width: $breakpoint-laptop) {
    padding: 100px 15%;
  }
}

.carrier-card-container {
  display: flex;
  padding-top: 10%;
  justify-content: space-between;
}

.page-title-margin-bottom {
  margin-bottom: 24px;
  @media (min-width: $breakpoint-laptop) {
    margin-bottom: 40px;
  }
}

.page-text-margin-bottom {
  margin-bottom: 24px;
  margin-right: 5%;
  @media (min-width: $breakpoint-laptop) {
    margin-bottom: 40px;
  }
}
