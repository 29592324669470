@import "../../App.scss";

.jobs-main-container {
  background-color: $backgroundColor;
  padding: 40px 5% 60px 5%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    padding: 50px 12% 100px 12%;
  }

  @media (min-width: $breakpoint-laptop) {
    padding: 50px 15% 100px 15%;
  }
}

.jobs-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 100%;
  max-width: 1080px;
  position: relative;
}

.fixed_container{
  position: absolute;
  bottom: 0;
  left: 0;
  background: #ffffff;
  z-index: 1000;
  width: 100%;
  height: 50px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.job-category-container {
  padding: 35px 40px;
  text-align: left;

  p {
    font-family: InterExtraBold;
    font-size: 24px;
    color: $pageTitleColor;
  }
}

.jobs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 27px 0 32px 0;

  @media (min-width: $breakpoint-tablet) {
    padding: 14px 0;
    flex-direction: row;
    align-items: center;
  }
}

.job-details {
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
    align-items: center;
  }
}

.job-apply-button {
  border: none;
  background-color: $pageTitleColor;
  border-radius: 100px;
  color: white;
  height: 40px;
  width: 140px;
  font-family: InterMedium;
  font-size: 18px;
  line-height: 1.5;
}

.job-title-text {
  vertical-align: middle;
  font-family: InterMedium;
  font-size: 18px;
  line-height: 1.5;

  @media (min-width: $breakpoint-tablet) {
  }
}

.job-location-text {
  vertical-align: middle;
  font-family: InterMedium;
  font-size: 18px;
  line-height: 1.5;
  color: $locationTextColor;
  margin: 0 0 0 10px;
  @media (min-width: $breakpoint-tablet) {
    margin: 0 40px 0 0;
  }
}

.job-location-icon {
  @media (min-width: $breakpoint-tablet) {
    margin-right: 22px;
  }
}

.job-location-container {
  margin: 8px 0 14px 0;
  @media (min-width: $breakpoint-tablet) {
    margin: 0;
  }
}

.padding-job-text {
  padding: 15px 0 40px 0;
  max-width: 870px;
}
