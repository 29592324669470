@import "../../App.scss";
$shadowColor: #c4c4c4;

.color-card {
  margin: 5px 0px 5px 5px;
  display: flex;
  flex-direction: column;
  max-width: 296px;
  min-height: 306px;
  width: 100%;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.12);
  padding: 40px;
  border-radius: 12px;
  background-color: white;
  @media (min-width: $breakpoint-tablet) {
    max-width: 400px;
    margin: 0;
  }
}

.circular-color-div {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: $breakpoint-tablet) {
    width: 65px;
    height: 65px;
  }
}

.color-card-title {
  padding-top: 40px;
  font-size: 16px;
  font-family: InterBoldTwo;
  line-height: 1.5;
  text-align: left;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
    line-height: 1.5;
  }
}

.color-card-text {
  font-size: 12px;
  font-family: Inter;
  line-height: 1.5;
  text-align: left;
  margin: 16px 0 0;

  @media (min-width: $breakpoint-tablet) {
    margin: 32px 0 0;
    font-size: 14px;
    line-height: 1.5;
  }
}
