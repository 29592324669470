@import "../../App.scss";

$footerColor: #062136;
$footerHeight: 280px;
$footerHeightMobile: 535px;

@mixin column-flex {
  display: flex;
  flex-direction: column;
  padding: 30px 0 30px 0;
  @media (min-width: $breakpoint-tablet) {
    padding: 65px 0;
  }
}

.main-container {
  display: flex;
  background-color: $footerColor;
  height: $footerHeightMobile;
  padding: 0% 5%;
  justify-content: space-between;
  color: white;

  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    padding: 0% 5%;
    height: $footerHeight;
  }

  @media (min-width: $breakpoint-laptop) {
    padding: 0% 15%;
    height: $footerHeight;
  }
}

.left-column {
  @include column-flex;
  width: 100%;
  color: white;
  text-align: left;

  @media (min-width: $breakpoint-tablet) {
    justify-content: center;
  }
}

// .right-column {
//   color: white;
//   @include column-flex;
// }

.footer-links-container {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  text-align: center;
  flex: 1;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
  }
}

.footer-link {
  color: white;
  padding: 12px 0;
  font-family: Inter;
  text-decoration: none;
  @media (min-width: $breakpoint-tablet) {
    padding: 0 24px 0 0;
  }
}

.footer-link:hover {
  text-decoration: underline;
  color: white;
}
