@import "../../App.scss";

.shippers-tech-container {
  display: flex;
  flex-direction: column;
  background-color: $backgroundColor;
  padding: 40px 5%;
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    padding: 100px 5%;
    flex-direction: row;
  }

  @media (min-width: $breakpoint-laptop) {
    padding: 100px 15%;
    flex-direction: row;
  }
}

.shippers-tech-column {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  @media (min-width: $breakpoint-tablet) {
    // margin: auto;
  }
}

.shippers-tech-cards-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media (min-width: $breakpoint-tablet) {
    flex-direction: column;
  }
}

.shippers-tech-card {
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin: 40px 0;
  font-family: InterBold;
  align-items: center;
  p {
    margin: auto 0;
    font-family: InterBold;
    font-size: 16px;
    line-height: 1.5;
  }

  @media (min-width: $breakpoint-tablet) {
    height: 113px;
    width: 440px;
    border-radius: 17px;
    margin: 12px 0;
  }
}

.devices-image {
  width: 90%;
  height: 100%;
}

.devices-image-container {
  padding-bottom: 75px;
  display: flex;
  justify-content: flex-end;

  @media (min-width: $breakpoint-tablet) {
    flex: 0.7;
    padding-bottom: 0;
    max-width: 833px;
    max-height: 711px;
  }
}

.shippers-notepad-container {
  display: flex;
  flex-direction: column;
  padding: 0 5%;
  margin: 34px 0 0;
  @media (min-width: $breakpoint-tablet) {
    justify-content: space-between;
    flex-direction: row;
    padding: 110px 5%;
  }
  @media (min-width: $breakpoint-laptop) {
    padding: 110px 15%;
  }
}

.shippers-mission-container {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  align-items: center;
  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: $breakpoint-laptop) {
    padding-left: 15%;
    justify-content: space-between;
  }
}

.shippers-mission-description {
  display: flex;
  flex-direction: column;
  // padding-left: 5%;
  flex: 0.3;
  @media (min-width: $breakpoint-tablet) {
  }
}

.shippers-tech-text {
  margin: 24px 0 0;

  @media (min-width: $breakpoint-tablet) {
    margin: 38px 0 0;
  }
}

.shippers-tech-image {
  max-width: 620px;
  max-height: 540px;
  width: 100%;
  height: 100%;
  margin: auto;
  @media (min-width: $breakpoint-tablet) {
    margin: 96px auto 0;
  }
}
