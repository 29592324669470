@import "../../App.scss";

.carrier-tech-container {
  padding: 0 5%;
  margin-bottom: 36px;

  @media (min-width: $breakpoint-laptop) {
    padding: 0 15% 0 15%;
    margin-bottom: 0;
  }
}

.tech-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5%;
  justify-content: space-between;

  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    flex-direction: row;
    padding: 40px 5% 124px 5%;
  }

  @media (min-width: $breakpoint-laptop) {
    flex-direction: row;
    padding: 40px 15% 124px 15%;
  }
}

.image-column {
  // display: flex;
  @media (min-width: $breakpoint-tablet) {
    flex: 0.48;
  }
}

.tech-image-container {
  width: 100%;
  object-fit: cover;
  max-height: 400px;
  // background-position: center;

  @media (min-width: $breakpoint-tablet) {
    border-radius: 12px;
    width: 100%;
    max-height: 570px;
    max-width: 620px;
    // transform: translateX(-20px);
    // animation: name duration timing-function delay iteration-count direction fill-mode;
  }
}

// .tech-image-right-radius {
//   @media (min-width: $breakpoint-tablet) {
//     border-top-right-radius: 12px;
//     border-bottom-right-radius: 12px;
//   }
// }

// .tech-image-left-radius {
//   @media (min-width: $breakpoint-tablet) {
//     border-top-left-radius: 12px;
//     border-bottom-left-radius: 12px;
//   }
// }

.description-column {
  padding: 40px 0;
  @media (min-width: $breakpoint-tablet) {
    flex: 0.48;
  }
}

.title-padding {
  @media (min-width: $breakpoint-tablet) {
    padding: 0 10%;
  }
}

.text-padding {
  margin: 16px 0 0;

  @media (min-width: $breakpoint-tablet) {
    padding: 0 25% 0 10%;
    margin: 32px 0 0;
    line-height: 2;
  }
}

.carrier-tech-title-max-width {
  max-width: 950px;
}
