@import "../../App.scss";

.hiring-title-max-width {
  max-width: 496px;
  margin-bottom: 24px;
  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 32px;
  }
}

.hiring-text-max-width {
  max-width: 607px;
}

.hiring-button-margin-top {
  margin-top: 32px;
  margin-bottom: 46px;

  @media (min-width: $breakpoint-tablet) {
    margin-top: 48px;
    margin-bottom: 0;
  }
}

.hiring-desc-image {
  width: 100%;
  object-fit: cover;

  @media (min-width: $breakpoint-tablet) {
    max-width: 674px;
    border-radius: 12px;
    height: 449px;
    box-shadow: 0 1px 24px rgba(0, 0, 0, 0.24);
  }
}

.hiring-desc-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    padding: 0 5%;
    flex-direction: row;
    margin: 54px 0 127px 0;
  }

  @media (min-width: $breakpoint-laptop) {
    padding: 0 15%;
    flex-direction: row;
    margin: 54px 0 127px 0;
  }
}

.hiring-desc-column-left {
  margin-top: 46px;
  padding: 0 5%;
  @media (min-width: $breakpoint-tablet) {
    padding-left: 0;
  }
}
