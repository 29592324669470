@import "../../App.scss";

.hiring-values-container {
  background-color: #edf7ff;
  text-align: center;
  padding: 35px 5% 0 5%;

  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    padding: 80px 5%;
  }
  @media (min-width: $breakpoint-laptop) {
    padding: 80px 15%;
  }
}

.hiring-values-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 35px;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
    padding-top: 80px;
    padding-bottom: 40px;
  }
}

.hiring-values-section-title {
  font-size: 22px;
  font-family: InterExtraBold;
  line-height: 1.5;
  text-align: left;

  @media (min-width: $breakpoint-tablet) {
    font-size: 40px;
    text-align: center;
  }
}
