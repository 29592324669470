@import "../../App.scss";

.notepad-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 32px;
  margin-right: 40px;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
    width: 380px;
    height: 180px;
    margin-bottom: 0;
    margin-right: 58px;
  }
}

.notepad-title {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
}

.notepad-text {
  font-size: 16px;
  line-height: 2;
  font-family: Inter;
  margin: 16px 0 0;
  @media (min-width: $breakpoint-tablet) {
    margin: 18px 0 0;
  }
}

.notepad-icon {
  background-color: white;
  width: 32px;
  height: 32px;
}

.notepad-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  height: 48px;
  width: 48px;
  margin-bottom: 24px;

  @media (min-width: $breakpoint-tablet) {
    height: 64px;
    width: 64px;
  }
}
