@import "../../App.scss";

$clientColor: hsl(231, 13%, 11%);
$backGroundColor: #edf7ff;

.main-container-client {
  display: flex;
  flex-direction: column;
  padding: 59px 0;
  background-color: $backGroundColor;
  text-align: center;
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    padding: 59px 10%;
    height: 396px;
  }

  @media (min-width: $breakpoint-laptop) {
    padding: 59px 15%;
    height: 396px;
  }
}

.client-card-container {
  display: flex;
  padding-top: 50px;
  justify-content: space-evenly;
}
