@import "../../App.scss";

$missionColor: #1c75bc;

.mission-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    flex-direction: row;
    padding: 0 5%;
  }

  @media (min-width: $breakpoint-laptop) {
    flex-direction: row;
    padding: 0 15%;
  }
}

.mission-left-column {
  // flex: 0.5;
  padding: 20px 10% 20px 5%;

  @media (min-width: $breakpoint-tablet) {
    padding: 110px 0;
  }
}

.mission-right-column {
  // flex: 0.3;
}

.mission-title {
  font-size: 26px;
  line-height: 1.3;
  color: $missionColor;
  font-family: InterBold;
  text-align: left;

  @media (min-width: $breakpoint-tablet) {
    font-size: 54px;
  }
}

.mission-text {
  font-size: 14px;
  font-family: Inter;
  line-height: 2;
  text-align: left;

  @media (min-width: $breakpoint-tablet) {
    font-size: 24px;
  }
}

.mission-image1 {
  width: 100%;
  height: 220px;
  object-fit: cover;

  @media (min-width: $breakpoint-tablet) {
    border-radius: 24px;
    height: 624px;
    max-width: 636px;
    min-width: 558px;
  }
}

// .mission-image2 {
//   width: 179px;
//   height: 179px;
//   position: absolute;
//   bottom: 71px;
//   left: -80px;
//   border: 10px solid white;
// }

.page-title-max-width {
  max-width: 607px;
}

.page-text-max-width {
  max-width: 590px;
  margin-top: 28px;
  @media (min-width: $breakpoint-tablet) {
    margin-top: 32px;
  }
}
