@import "../../App.scss";

.values-container {
  display: flex;
  flex-direction: column;
  padding: 32px 0px 32px 5%;
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
    flex-direction: row;
    padding: 100px 0px 100px 5%;
  }

  @media (min-width: $breakpoint-laptop) {
    flex-direction: row;
    padding: 100px 0px 100px 15%;
  }
}

.values-column-left {
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (min-width: $breakpoint-tablet) {
    flex: 0.3;
    min-width: 383px;
  }
}

.values-cards-container {
  display: flex;
  padding: 10px 0;
  @media (min-width: $breakpoint-tablet) {
    overflow-x: hidden;
    scroll-behavior: smooth;
    flex: 0.7;
  }
}

.values-title-max-width {
  max-width: 307px;
}

.values-text-max-width {
  max-width: 369px;
  margin: 16px 0 32px;
  @media (min-width: $breakpoint-tablet) {
    margin: 32px 0 0;
  }
}

.arrow-buttons {
  border: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 32px;
  margin-top: 50px;
  background-color: $pageTitleColor;
  color: white;
  font-size: 28px;
  font-family: InterBold;
  display: flex;
  justify-content: center;
  align-items: center;

  :active {
    background-color: $pageTitleColor;
  }
}
